import { Router } from '@reach/router';
import { Layout } from 'components/layout';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useProgramDocuments } from 'hooks';
import { ProgramDetailsPage, ProgramPage } from 'modules/program';
import React from 'react';

const Program: React.FC<PageProps> = () => {
  const { locale } = useIntl();
  const documents = useProgramDocuments(locale);

  return (
    <Layout isGhostNav={false}>
      <Router>
        <ProgramPage path={`/${locale}/program/`} documents={documents} />
        <ProgramDetailsPage
          path={`/${locale}/program/:uid`}
          documents={documents}
        />
      </Router>
    </Layout>
  );
};

export default Program;
